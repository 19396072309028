<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">C. Calibrating the System</h2>
      <p class="mb-3">
        a) From the calibration portion of the experiment, fill in the measured values below. Note:
        for the measurement of V<sub>flask</sub>, assume that the density of water is 1.00 g/mL.
      </p>

      <p class="mb-5">
        <calculation-input
          v-model="inputs.massH2Oflask"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{Mass H}_2\text{O in flask:}$"
          append-text="$\text{g}$"
          dense
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.Vflask"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{V}_\text{flask}\text{:}$"
          append-text="$\text{mL}$"
          dense
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.massH2Omanometer"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{Mass H}_2\text{O in manometer:}$"
          append-text="$\text{g}$"
          dense
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.Vmanometer"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{V}_\text{manometer}\text{:}$"
          append-text="$\text{mL}$"
          dense
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-3">
        b) Enter the required measured lengths below and use those to calculate the total length of
        the manometer.
      </p>

      <calculation-input
        v-model="inputs.lengthLeftSide"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{Length of left side:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.lengthRightSide"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{Length of right side:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.radiusU"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{Radius of U-section:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.lengthU"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{Length of U-section:}$"
        append-text="$\text{cm}$"
        dense
        hint="Length of U-section = π x radius"
        :disabled="!allowEditing"
      />

      <!--      TODO: Why can't I add hints?-->

      <calculation-input
        v-model="inputs.totalLength"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{Total length of manometer:}$"
        append-text="$\text{cm}$"
        dense
        hint="Total length = length of left side + length of right side + length of U-section"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) Determine the cross-sectional area, A, of the manometer using the following equation:
      </p>

      <p class="mb-3 pl-14">
        <stemble-latex
          content="$\text{A = }\displaystyle{\frac{\text{V}_\text{manometer}}{\text{Total length of manometer}}}$"
        />
      </p>

      <calculation-input
        v-model="inputs.areaManometer"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{A:}$"
        append-text="$\text{cm}^2$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        d) Fill in the required heights below based on the data recorded in part B of this
        experiment:
      </p>

      <calculation-input
        v-model="inputs.h1left"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{1,l}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h2left"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{2,l}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h1right"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{h}_\text{1,r}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        e) Determine the change in volume in the manometer which can be calculated as follows:
      </p>

      <p class="mb-3 pl-14">
        <stemble-latex
          content="$\Delta\text{V = (h}_\text{2,l}-\text{h}_\text{1,l})\times\text{A}$"
        />
      </p>

      <calculation-input
        v-model="inputs.volumeChange"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\Delta\text{V:}$"
        append-text="$\text{cm}^3$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        f) Input the change in pressure, &#x394;P, determined in part B of the experiment:
      </p>

      <calculation-input
        v-model="inputs.deltaP"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\Delta\text{P:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        g) Calculate the initial pressure, P<sub>1</sub>, using the following equation:
      </p>

      <p class="mb-3 pl-14">
        <stemble-latex
          content="$\text{P}_1=\displaystyle{\frac{\left( \text{V}_\text{flask}\Delta\text{P}-\Delta\text{V}\Delta\text{P}\right)}{\Delta\text{V}}}$"
        />
      </p>

      <calculation-input
        v-model="inputs.P1"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{P}_1\text{:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        h) Finally, determine the atmospheric pressure, P<sub>atm</sub>, by rearranging the
        following equation:
      </p>

      <p class="mb-3 pl-14">
        <stemble-latex
          content="$\text{P}_1=\text{P}_\text{atm}+\text{h}_\text{1,r}-\text{h}_\text{1,l}$"
        />
      </p>

      <calculation-input
        v-model="inputs.Patm"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{P}_\text{atm}\text{:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'GasesReportSheetC',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massH2Oflask: null,
        massH2Omanometer: null,
        Vflask: null,
        Vmanometer: null,
        lengthLeftSide: null,
        lengthRightSide: null,
        radiusU: null,
        lengthU: null,
        totalLength: null,
        areaManometer: null,
        h1left: null,
        h2left: null,
        h1right: null,
        deltaP: null,
        volumeChange: null,
        P1: null,
        Patm: null,
      },
    };
  },
};
</script>
